import React, { useState } from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import Col from "react-bootstrap/Col"
import translationHelper from "../i18n/helper"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import PortfolioItems from "../components/Portfolio/PortfolioItems/portfolioItems"
import decode from "unescape"
import { navigate } from "@reach/router"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"


const PortfolioPage = (props) => {

  const { hash } = props.location
  const [tabSelected, setTabSelected] = useState(hash ? hash.replace("#","") : "all")

  const projects = props.data.allWordpressWpProjects.edges
  const projectCategories = props.data.allWordpressWpProjectCategories.edges
  const locale = props.pageContext.locale

  // Change navigation to #key so we can know if someone press the back button which tab was active
  const tabClicked = (key) => {
    setTabSelected(key)
    navigate("#" + key)
  }

  let projectCategoriesMapped = {}

  projectCategories.forEach(category => {
    projectCategoriesMapped[category.node.wordpress_id] = category.node.name
  })


  // Map the Portfolio Items to Categories

  let projectsMapped = {}
  projects.forEach(edge => {

    edge.node.project_categories.forEach(category => {
      // if Category Id is already set at Object
      if (projectsMapped[category]) {
        projectsMapped[category].push(edge)

      } else { // Else Create the key first

        let tmpObj = {
          [category]: [],
        }
        projectsMapped = { ...projectsMapped, ...tmpObj }
        projectsMapped[category].push(edge)
      }
    })

  })


  return (
    <Layout locale={locale} path={props.path}>
      <SEO title={translationHelper[locale]["portfolioPageTitle"]}
           lang={props.pageContext.locale}
           description={translationHelper[locale]["portfolioPageDescription"]}
      />
      <EmptySpaceSeperator pixels={40}/>
      <SectionTitleSeperator h1Override={true} hideTop={true} title={translationHelper[locale]["Portfolio"]}/>
      <Container>
        <Row>
          <Col md={12}>
            <Tabs onSelect={tabClicked} defaultActiveKey={tabSelected ? tabSelected : "all"} className="tablist"
                  id="portfolioTabs">
              <Tab eventKey="all" title={translationHelper[locale]["All"]}>
                <PortfolioItems categoryByArray={projectCategoriesMapped} items={projects}/>
              </Tab>
              {
                projectCategories.map((category) => {
                  return (
                    <Tab eventKey={category.node.wordpress_id} style={{ color: "#000" }}
                         key={category.node.wordpress_id} title={decode(category.node.name)}>
                      {projectsMapped[category.node.wordpress_id]
                        ? <PortfolioItems categoryId={category.node.wordpress_id}
                                          categoryByArray={projectCategoriesMapped}
                                          items={projectsMapped[category.node.wordpress_id]}/>
                        : null
                      }

                    </Tab>
                  )
                })
              }
            </Tabs>
          </Col>
        </Row>
      </Container>

    </Layout>
  )
}

export default PortfolioPage
export const portfolioPageQuery = graphql`
    query($locale: String!) {
        allWordpressWpProjects(filter: {polylang_current_lang: {eq: $locale}}) {
            edges {
                node {
                    id
                    slug
                    path
                    project_categories
                    title
                    wordpress_id
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpProjectCategories(filter: {polylang_current_lang: {eq: $locale}}) {
            edges {
                node {
                    slug
                    path
                    wordpress_id
                    name
                }
            }
        }
    }
`